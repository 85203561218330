import React from "react";
import Image from "../image";
import { getAppMedia, mediaTypes } from "../../utils/media";
import PlateIcon from "../icons/Plate.svg";

export default ({ appStyles, sizes = "90px", wrapperStyle = { width: 90, height: 90 }, isWidget, imgStyle}) => {
  const defaultItemImage = getAppMedia(mediaTypes.defaultItemImage, appStyles);
  return defaultItemImage ? (
    <Image
      mediaKey={defaultItemImage.imageKey}
      mediaType={mediaTypes.defaultItemImage}
      imagePreview={defaultItemImage.imagePreview}
      isWidget={isWidget}
      {...!isWidget && {
        sizes,
        wrapperStyle,
        imgStyle: { ...imgStyle, objectFit: "contain" },
      }}
    />
  ) : (
    <div
      style={{
        ...wrapperStyle,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PlateIcon style={{ width: "90%", height: "90%" }} />
    </div>
  );
};
