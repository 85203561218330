import {
  Box,
  Collapse,
  Grid,
  Card as CardMUI,
  CardContent,
  Typography,
} from "@mui/material";
import {
  countBy,
  filter,
  find,
  get,
  includes,
  indexOf,
  isEmpty,
  join,
  map,
  max,
  noop,
} from "lodash";
import React, { useMemo, useState } from "react";
import {
  isRadioButtonGroup,
  shouldEnableMultiSelect,
  priceForSequenceOption,
} from "../../utils/menuItemOrderData";
import Card from "../Card";
import ExpandMore from "../expand-more";
import TastePreferenceDecorator from "./TastePreferenceDecorator";
import * as styles from "./index.module.scss";
import Image from "../image";
import NoImageRenderer from "../no-image-renderer";
import { mediaTypes } from "../../utils/media";
import QuantityInput from "../quanitity-input";

export default function OrderItemComponent(props) {
  const { orderItemComponent, onChange = noop, posInnerMenuId } = props;
  const {
    appStyles,
    pageContext: {
      business: { currencySymbol, allergens },
      kioskMode,
    },
    T,
    firstComponentWithError,
    animateErrorElement,
    isMobile,
  } = props;

  const {
    maxNumOfMarkedOptionsIncluded,
    minNumOfMarkedComponentOptions,
    maxNumOfMarkedOptions,
    chosenOrderItemComponentOptions,
    priceAdditional,
    sequencePrices,
  } = orderItemComponent;

  const [displayRadioButtonGroup, enableMultiSelect] = useMemo(
    () => [
      isRadioButtonGroup(orderItemComponent),
      shouldEnableMultiSelect(orderItemComponent),
    ],
    [JSON.stringify(orderItemComponent)]
  );
  const limit = useMemo(
    () => Math.max(maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded),
    [maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]
  );

  const getMaxForComponentOption = (componentOption) => {
    return max([maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]) > -1
      ? max([maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]) -
          filter(
            chosenOrderItemComponentOptions,
            (id) => id !== componentOption.id
          ).length
      : 100;
  };

  const ComponentOptionPrice = ({ componentOption, addPlusSign }) => {
    const chosenOptionPosInnerMenuItem = find(
      componentOption.posInnerMenuSpecificPrices,
      { posInnerMenuId }
    );

    const componentOptionPrice =
      chosenOptionPosInnerMenuItem &&
      typeof chosenOptionPosInnerMenuItem.price === "number"
        ? chosenOptionPosInnerMenuItem.price
        : componentOption.price;

    const indexInChosen = indexOf(
      chosenOrderItemComponentOptions,
      componentOption.id
    );
    const showPriceAdditional =
      !isEmpty(orderItemComponent.chosenOrderItemComponentOptions) &&
      orderItemComponent.chosenOrderItemComponentOptions.length >=
        maxNumOfMarkedOptionsIncluded &&
      (indexInChosen >= maxNumOfMarkedOptionsIncluded || indexInChosen === -1);

    const price =
      (componentOptionPrice || 0) +
      ((showPriceAdditional && priceAdditional) || 0) +
      priceForSequenceOption(componentOption, orderItemComponent);

    if (appStyles.hidePlusSign && price < 0) {
      return (
        <span className={styles.ComponentOptionPrice}>
          {" "}
          {currencySymbol}
          {price * -1} off
        </span>
      );
    }
    return price > 0 ? (
      <span className={styles.ComponentOptionPrice}>
        {addPlusSign && "+"}
        {currencySymbol}
        {appStyles.pricesFixedPoint
          ? price.toFixed(appStyles.pricesFixedPoint)
          : price}
      </span>
    ) : <div style={{minWidth: "60px"}}></div>;
  };

  const ComponentOption = ({ componentOption, T, appStyles, allergens, shouldShowPlusSign }) => (
    <div style={{ paddingTop: 4, width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TastePreferenceDecorator
            title={T(componentOption.title)}
            appStyles={appStyles}
            allergens={allergens}
          />
          {componentOption.description && (
            <span>
              <small style={{ color: "#555" }}>
                {T(componentOption.description)}
              </small>
            </span>
          )}
          {componentOption.calories && !appStyles.hideCaloriesForMenuItems &&(
            <span>
              <small style={{ color: appStyles.actionColor }}>
                {T(componentOption.calories)} Cal
              </small>
            </span>
          )}
        </div>
        <ComponentOptionPrice
          componentOption={componentOption}
          addPlusSign={shouldShowPlusSign && appStyles.hidePlusSign !== true}
        />
      </div>
    </div>
  );

  const shouldShowPlusSign = !(
    minNumOfMarkedComponentOptions > 0 &&
    !find(
      orderItemComponent.componentOptions,
      (componentOption) => !componentOption.price
    )
  );

  const [expanded, setExpanded] = useState(
    (appStyles.expandModifiersIfLessThan > 0 &&
      appStyles.expandModifiersIfLessThan >
        orderItemComponent.componentOptions.length) ||
      minNumOfMarkedComponentOptions > 0 ||
      !appStyles.collapseModifiersByDefault
  );

  const choesenCounter = countBy(
    orderItemComponent.chosenOrderItemComponentOptions
  );

  const chosenTitles = useMemo(
    () =>
      map(
        filter(orderItemComponent.componentOptions, ({ id }) =>
          includes(orderItemComponent.chosenOrderItemComponentOptions, id)
        ),
        ({ title, id }) => {
          const count = choesenCounter[id];
          if (count > 1) {
            return `${count} × ${T(title)}`;
          }
          return T(title);
        }
      ),
    [JSON.stringify(orderItemComponent.chosenOrderItemComponentOptions)]
  );

  const mainHeader = includes(orderItemComponent.title, "(")
    ? orderItemComponent.title.substring(
        0,
        indexOf(orderItemComponent.title, "(")
      )
    : orderItemComponent.title;
  const sideHeaderNote = includes(orderItemComponent.title, "(")
    ? orderItemComponent.title.substring(indexOf(orderItemComponent.title, "("))
    : null;

  const getVariant = () => {
    if (displayRadioButtonGroup) {
      return "radio";
    }
    if (enableMultiSelect) {
      return "multiple";
    }
    return "checkbox";
  };

  const ModifierWrapper = ({ variant, limit, orderItemComponent }) => {
    const handleChoseOrderItemComponentOption = (
      chosenOrderItemComponentOption,
      amount
    ) => {
      const payload = { ...orderItemComponent };

      if (variant === "radio") {
        payload.chosenOrderItemComponentOptions = [
          chosenOrderItemComponentOption.id,
        ];
      }

      if (variant === "checkbox") {
        payload.chosenOrderItemComponentOptions = orderItemComponent.chosenOrderItemComponentOptions.includes(
          chosenOrderItemComponentOption.id
        )
          ? orderItemComponent.chosenOrderItemComponentOptions.filter(
              (option) => option !== chosenOrderItemComponentOption.id
            )
          : [
              ...orderItemComponent.chosenOrderItemComponentOptions,
              chosenOrderItemComponentOption.id,
            ];
      }

      if (variant === "multiple") {
        payload.chosenOrderItemComponentOptions = [
          ...orderItemComponent.chosenOrderItemComponentOptions.filter(
            (id) => id !== chosenOrderItemComponentOption.id
          ),
          ...Array(amount).fill(chosenOrderItemComponentOption.id),
        ];
      }

      onChange(payload);
    };

    return (
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        justifyContent="space-evenly"
      >
        {map(
          orderItemComponent.componentOptions,
          (componentOption, index) => (
            <Grid
              item
              key={index}
              display="flex"
              justifyContent="center"
              sx={{ ...(isMobile && { width: "50%" }) }}
            >
              <ModifierItem
                index={index}
                selected={orderItemComponent.chosenOrderItemComponentOptions.includes(
                  componentOption.id
                )}
                disabled={
                  variant !== "radio" &&
                  orderItemComponent.chosenOrderItemComponentOptions
                    .length >= limit &&
                  !orderItemComponent.chosenOrderItemComponentOptions.includes(
                    componentOption.id
                  )
                }
                componentOption={componentOption}
                onClick={(amount) =>
                  handleChoseOrderItemComponentOption(
                    componentOption,
                    amount
                  )
                }
                variant={variant}
              />
            </Grid>
          )
        )}
        {//Add an empty grid item if the number of components is odd
        orderItemComponent.componentOptions.length % 2 !== 0 ? (
          <Grid item sx={{ ...(isMobile && { width: "50%" }) }}>
            <div style={{ ...(isMobile ? { maxWidth: 175 } : { width: 250 }) }} />
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const ModifierItem = ({
    index,
    componentOption,
    onClick,
    selected,
    disabled,
    variant,
  }) => {
    const imageProps = {
      ...{
        wrapperStyle: {
          height: isMobile ? 90 : 120,
          width: isMobile ? 175 : 250,
        },
      },
    };

    const selectedStyles = {
      border: `2px solid ${appStyles.actionColor}`,
      "& .MuiCardContent-root": {
        backgroundColor: `${appStyles.actionColor}1A`,
        borderRadius: 2,
      },
      [`& .${styles.ItemComponentOption} .MuiTypography-root`]: {
        color: `${appStyles.actionColor}`,
      },
      "& .MuiTypography-root": {
        fontWeight: "bold",
      },
    };

    return (
      <CardMUI
        sx={{
          height: "100%",
          maxWidth: isMobile ? 175 : 250,
          border: `1px solid ${appStyles.textColor}`,
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "not-allowed" : "pointer",
          borderRadius: 2,
          ...(selected && selectedStyles),
        }}
        onClick={disabled || variant === "multiple" ? undefined : onClick}
      >
        <CardContent
          sx={{
            p: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
            height: "100%",
          }}
        >
          <div
            key={index}
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {get(componentOption, "imageKey") ? (
              <Image
                mediaKey={componentOption.imageKey}
                mediaType={mediaTypes.menuItemModifier}
                imagePreview={componentOption.imagePreview}
                {...imageProps}
                imgStyle={{objectFit:"contain"}}
              />
            ) : (
              <NoImageRenderer appStyles={props.appStyles} {...imageProps} />
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: `calc(100% - ${imageProps.wrapperStyle.height}px - 20px)`, // the height of the image + padding
              justifyContent: "space-between",
              padding: "10px",
              ...(isMobile && {padding: "5px", height: `calc(100% - ${imageProps.wrapperStyle.height}px - 10px)`}),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ...(isMobile && {padding: "7px", paddingBottom: "5px"}),
              }}
            >
              <TastePreferenceDecorator
                title={T(componentOption.title)}
                appStyles={appStyles}
                allergens={allergens}
                titleStyles={{ fontWeight: "bold", color: appStyles.textColor }}
              />
              {componentOption.description && (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: "text.secondary" }}
                >
                  {T(componentOption.description)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...(isMobile && {paddingLeft: "7px"}),
              }}
            >
              <ComponentOptionPrice
                componentOption={componentOption}
                index={index}
                addPlusSign={shouldShowPlusSign && !appStyles.hidePlusSign}
              />
              {variant === "multiple" && (
                <QuantityInput
                  size="small"
                  appStyles={appStyles}
                  readOnly
                  value={
                    filter(
                      orderItemComponent.chosenOrderItemComponentOptions,
                      (val) => val === componentOption.id
                    ).length
                  }
                  min={0}
                  max={
                    disabled
                      ? filter(
                          orderItemComponent.chosenOrderItemComponentOptions,
                          (val) => val === componentOption.id
                        ).length
                      : limit
                  }
                  onChange={onClick}
                  rounded={isMobile}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </CardMUI>
    );
  };

  const optionsHaveImage = get(orderItemComponent, "componentOptions")?.some(
    (option) => option.imageKey
  );
  const itemModifier =
  appStyles.useModifierImages && optionsHaveImage ? (
    <ModifierWrapper
      orderItemComponent={orderItemComponent}
      variant={getVariant()}
      limit={limit < 0 ? undefined : limit}
    />
  ) : displayRadioButtonGroup ? (
      <Card.Content.RadioGroup
        value={
          Array.isArray(orderItemComponent.chosenOrderItemComponentOptions) &&
          orderItemComponent.chosenOrderItemComponentOptions.length
            ? orderItemComponent.chosenOrderItemComponentOptions[0]
            : null
        }
        onChange={(chosenOrderItemComponentOption) =>
          onChange({
            ...orderItemComponent,
            chosenOrderItemComponentOptions: [chosenOrderItemComponentOption],
          })
        }
        wrap
        appStyles={appStyles}
      >
        {map(orderItemComponent.componentOptions, (componentOption, index) => (
          <Card.Content.RadioOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={appStyles.actionColor}
            value={componentOption.id}
            key={componentOption.id}
          >
            <span style={{ paddingTop: 4, width: "100%" }}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TastePreferenceDecorator
                  title={T(componentOption.title)}
                  appStyles={appStyles}
                  allergens={allergens}
                />
                <ComponentOptionPrice
                  componentOption={componentOption}
                  index={index}
                  addPlusSign={
                    shouldShowPlusSign && appStyles.hidePlusSign !== true
                  }
                />
              </span>
              {componentOption.description && (
                <span>
                  <small style={{ color: "#555" }}>
                    {T(componentOption.description)}
                  </small>
                </span>
              )}
            </span>
          </Card.Content.RadioOption>
        ))}
      </Card.Content.RadioGroup>
    ) : enableMultiSelect ? (
      <Card.Content.CardNumericGroup
        name={T(orderItemComponent.title)}
        limit={limit < 0 ? undefined : limit}
        checked={orderItemComponent.chosenOrderItemComponentOptions || []}
        onChange={(chosenOrderItemComponentOptions) =>
          onChange({
            ...orderItemComponent,
            chosenOrderItemComponentOptions,
          })
        }
        wrap
        appStyles={appStyles}
      >
        {map(orderItemComponent.componentOptions, (componentOption, index) => (
          <Card.Content.CardNumericInput
            T={T}
            key={componentOption.id}
            value={componentOption.id}
            values={orderItemComponent.chosenOrderItemComponentOptions}
            appStyles={appStyles}
            max={getMaxForComponentOption(componentOption)}
          >
            <span style={{ paddingTop: 4, width: "100%" }}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TastePreferenceDecorator
                  title={T(componentOption.title)}
                  appStyles={appStyles}
                  allergens={allergens}
                />
                <ComponentOptionPrice
                  componentOption={componentOption}
                  index={index}
                  addPlusSign={appStyles.hidePlusSign !== true}
                />
              </span>
              {componentOption.description && (
                <span>
                  <small>{T(componentOption.description)}</small>
                </span>
              )}
            </span>
          </Card.Content.CardNumericInput>
        ))}
      </Card.Content.CardNumericGroup>
    ) : (
      <Card.Content.CheckboxGroup
        name={T(orderItemComponent.title)}
        limit={limit < 0 ? undefined : limit}
        checked={orderItemComponent.chosenOrderItemComponentOptions || []}
        onChange={(chosenOrderItemComponentOptions) =>
          onChange({
            ...orderItemComponent,
            chosenOrderItemComponentOptions,
          })
        }
        wrap
        appStyles={appStyles}
      >
        {map(orderItemComponent.componentOptions, (componentOption, index) => (
          <Card.Content.CheckboxOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={appStyles.actionColor}
            value={componentOption.id}
            key={componentOption.id}
          >
            <span style={{ paddingTop: 4, width: "100%" }}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TastePreferenceDecorator
                  title={T(componentOption.title)}
                  appStyles={appStyles}
                  allergens={allergens}
                />
                <ComponentOptionPrice
                  componentOption={componentOption}
                  index={index}
                  addPlusSign={appStyles.hidePlusSign !== true}
                />
              </span>
              {componentOption.description && (
                <span>
                  <small style={{ color: "#555" }}>
                    {T(componentOption.description)}
                  </small>
                </span>
              )}
            </span>
          </Card.Content.CheckboxOption>
        ))}
      </Card.Content.CheckboxGroup>
    );

  return (
    <Card
      style={{ paddingBottom: expanded ? 10 : 10, cursor: "pointer" }}
      appStyles={appStyles}
      key={orderItemComponent.id}
      refEl={props.refEl}
      animateError={firstComponentWithError && animateErrorElement}
      kioskMode={kioskMode}
      hasErrors={firstComponentWithError}
    >
      <Card.Header onClick={() => setExpanded(!expanded)}>
        <Grid container direction="column" spacing={1} alignItems="flex-start">
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Card.Header.Title appStyles={appStyles}>
                {T(mainHeader)}
              </Card.Header.Title>
              {sideHeaderNote && (
                <Card.Header.SideNote appStyles={appStyles}>
                  {T(sideHeaderNote)}
                </Card.Header.SideNote>
              )}
            </Grid>
            <Grid item>
              <Card.Header.SideNote appStyles={appStyles}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  wrap="nowrap"
                >
                  {limit > 0 && !displayRadioButtonGroup && (
                    <Grid item>
                      <small>
                        {T("max.")} {limit}
                      </small>
                    </Grid>
                  )}
                  {minNumOfMarkedComponentOptions > 0 && (
                    <Grid item>
                      <strong style={{ color: appStyles.actionColor }}>
                        {T("Required")}
                      </strong>
                    </Grid>
                  )}
                  <Grid item>
                    <Box sx={{ marginRight: 0.5 }}>
                      <ExpandMore
                        expanded={expanded}
                        handleExpandClick={() => setExpanded(!expanded)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card.Header.SideNote>
            </Grid>
          </Grid>
          <Grid item>
            <Collapse in={!expanded}>
              <small>{join(chosenTitles, ", ")}</small>
            </Collapse>
          </Grid>
        </Grid>
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Content>{itemModifier}</Card.Content>
      </Collapse>
    </Card>
  );
}
