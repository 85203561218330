import _ from "lodash";
import React from "react";
import { Typography } from "@mui/material";
import AllergensDecorator from "../allergens-decorator";
import * as styles from "./index.module.scss";

const hasPreference = (title, preference) => {
  return title.indexOf(preference) >= 0;
};

const removePreferencesFromTitle = (title, preferences) => {
  preferences.map((pref) => {
    title = _.replace(title, pref, "");
  });
  return title;
};

export default ({ title, appStyles, allergens, titleStyles }) => (
  <span className={styles.ItemComponentOption}>
    <Typography component="span" sx={{...titleStyles}} variant="body1">
      {removePreferencesFromTitle(title, _.map(allergens, "tag"))}
    </Typography>
    <AllergensDecorator
      allergens={_.filter(
        allergens,
        (allergen) =>
          !_.isEmpty(allergen.tag) && hasPreference(title, allergen.tag),
      )}
      appStyles={appStyles}
    />
  </span>
);
